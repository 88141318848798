import React, { useState, useContext } from "react"
import api from "../utils/api"
import { Alert, Container, Row, Col, Form, Button } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import Seo from "../components/seo"
import { navigate } from "gatsby"
import LoadingOverlay from "react-loading-overlay"
import { NavbarContext } from "../context/navbar"

function RegistrationForm({
  thankYouModal,
  openThankYouModal,
  closeThankYouModal,
}) {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")
  const [profession, setProfession] = useState("Doctor")
  const [registrationNo, setRegistrationNo] = useState("")

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const navContext = useContext(NavbarContext)

  if (typeof window !== `undefined`) {
    window.dataLayer = window.dataLayer || []
  }

  const handleSubmit = async e => {
    e.preventDefault()

    setIsLoading(true)
    setError("")
    const user = {
      firstName,
      lastName,
      profession,
      registrationNo,
      email,
      password,
      confirm,
    }
    if (
      !firstName ||
      !lastName ||
      !profession ||
      !email ||
      !password ||
      !confirm
    ) {
      setIsLoading(false)
      return setError("Missing required(*) fields.")
    }
    if ((profession !== "Med Reps or Sales" || profession !== "Others") && !registrationNo) {
      setIsLoading(false)
      return setError("Registration number is required.")
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setIsLoading(false)
      return setError("Invalid email.")
    }
    if (password !== confirm) {
      setIsLoading(false)
      return setError("Password and confirm password do not match.")
    } else {
      try {
        const result = await api().post(`/users/register`, { ...user })

        localStorage.setItem("token", result.data.token)
        localStorage.setItem("user", JSON.stringify(result.data.user))
        localStorage.setItem("userId", result.data.user.id);
        navContext.setUser(JSON.stringify(result.data.user))
        window.dataLayer.push({ event: 'signup', userId: JSON.stringify(result.data.user.id), login_method: 'email' });

        setIsLoading(false)
        openThankYouModal()
        navigate(-2)
      } catch (error) {
        const message = error.response.data.message
        setIsLoading(false)
        setError(message)
      }
    }
  }

  return (
    <LoadingOverlay active={isLoading} spinner text="Registering...">
      <Seo
        title="Patameds Register"
        description="Register on Patameds using your Google or email address"
        siteUrl="https://www.patameds.com/register"
        canonical="https://www.patameds.com/register"
      ></Seo>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <div style={{ margin: "auto" }}>
            {error && <Alert variant="danger">{error}</Alert>}
          </div>
          <h1 className="text-center">Register</h1>

          <Form action={process.env.GATSBY_API_URL + "/users/auth/google"}>
            <Button
              type="submit"
              style={{ backgroundColor: "white", color: "black", border: "2px solid red" }}
            >
              <img
                class="google-icon"
              // src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
              />{" "}
              Register with Google
            </Button>
          </Form>

          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} controlId="firstName" className="mt-2">
              <Form.Label column sm="2">
                First Name*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  placeholder="John"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="lastName" className="mt-2">
              <Form.Label column sm="2">
                Last Name*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  placeholder="Doe"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="profession" className="mt-2">
              <Form.Label column sm="2">
                Profession*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  value={profession}
                  onChange={e => setProfession(e.target.value)}
                >
                  <option>Doctor</option>
                  <option>Nurse</option>
                  <option>Pharmacist</option>
                  <option>Pharmaceutical Technologist</option>
                  <option>Clinical Officer</option>
                  <option>Med Reps or Sales</option>
                  <option>Others</option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="email" className="mt-2">
              <Form.Label column sm="2">
                Email*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="registrationNo" className="mt-2">
              <Form.Label column sm="2">
                Registration Number
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  placeholder="PPB No. or KMPDB No. or NCK No."
                  value={registrationNo}
                  onChange={e => setRegistrationNo(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="password" className="mt-2">
              <Form.Label column sm="2">
                Password*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="password"
                  placeholder="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <Form.Text className="text-muted">
                  Password must contain an uppercase letter, a lower case
                  letter, a number and be at least 6 characters long
                </Form.Text>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="confirm" className="mt-2">
              <Form.Label column sm="2">
                Confirm password*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="password"
                  placeholder="confirm password"
                  value={confirm}
                  onChange={e => setConfirm(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Row
              className="mt-2"
              style={{ textAlign: "right" }}
            // className="mt-2"
            >
              <Col>
                <Button variant="primary" type="submit">
                  Register
                </Button>
                <p
                  css={`
                    text-align: center;
                  `}
                >
                  Using this platform means you agree to the{" "}
                  <a
                    href="https://www.patameds.com/terms_of_use"
                    target="_blank"
                    rel="noreferrer"
                  >
                    terms and condition
                  </a>
                </p>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </LoadingOverlay>
  )
}

export default function UserSignUp() {
  return (
    <NavbarContext.Consumer>
      {context => (
        <React.Fragment>
          <div className="vh-100 d-flex flex-column ">
            <Container style={{ marginTop: "100px" }}>
              <RegistrationForm
                thankYouModal={context?.thankYouModal}
                openThankYouModal={context?.openThankYouModal}
                closeThankYouModal={context?.closeThankYouModal}
              />
            </Container>
          </div>
        </React.Fragment>
      )}
    </NavbarContext.Consumer>
  )
}
